<template>
    <div>
    <!-- 原生 -->
        <div v-if="config.inputStatus==1" class="input_class">
            <div :class="config.ladelWidth?'title_i ladelWidth':'title_i'">{{config.title}}</div>
            <input placeholder-class="input_cla" :disabled="config.disabled" :style="{color: config.color}" type="" v-model="config.value" :placeholder="config.text" />
        </div>
        <!-- 文本域 -->
        <div v-else-if="config.textarea" style="display:flex">
            <div :class="config.ladelWidth?'title_i_t ladelWidth':'title_i_t'">{{config.title}}</div>
            <textarea class="text_form" v-model="config.value" rows="" cols=""></textarea>
        </div>
        <van-field v-else
        v-model="config.value"
        :name="config.title"
        :label="config.title"
        :placeholder="config.text"
        :rules="[{ required: true, message: config.text }]"
        placeholder-class="colors"
    />
    </div>
</template>
<script>
    export default{
        props:{
            config:{
                type:Object,
            }
        },
        data(){
            return{
                value: '',
                  
            }
        },
        methods:{

        }
    }
</script>
<style type="text/css">
    .colors{
        color: #fff
    }
    .van-field__control{
        color: #fff!important
    }
    .input_class{
        display:flex;
        font-size: 30px;
        line-height: 70px;
        color:#1A1A1A;
        margin-bottom: 40px
    }
    .input_class input{
        height:70px;
        width:380px;
        border-radius: 10px;
        border:2px solid #eee;
        margin-left:15px;
        padding-left:10px;
        font-size: 30px ;
        box-sizing: border-box
    }
    .title_i{
        text-align: right;
        width:200px;
        font-size: 30px;
        color:#1A1A1A
    }
    .title_i_t{
        text-align: right;
        width:150px;
        font-size: 30px;
        color:#1A1A1A
    }
    .ladelWidth{
        width:120px
    }
    .text_form{
        width:380px;
        margin-left:15px;
        border: 2px solid #eee;
        border-radius: 10px;
        font-size:30px
    }
</style>
