<template>
    <div class="contaners">

      <div @click="mineMessage" class="messageTitle">
            <img class="messageIcon" src="../../assets/phone2.png" />
            <div>联系客服</div>
          </div>
        <img src="../../assets/content_img.png" />
        <div class="from_box">
            <div class="from_info">提示：以下填写资料为《中华全国总工会会员证》所需资料，请根据实际填写！</div>
            <div class="from_title">工会会员入会表</div>
            <van-form @submit="onSubmit">
                <Input :config="dataForm.username"/>
                <Select :listArray="dataForm.sex.list" :config="dataForm.sex"></Select>
                <Input :config="dataForm.nation"/>
                <Select :listArray="dataForm.politics.list" :config="dataForm.politics"></Select>
                <Select :listArray="dataForm.education.list" :config="dataForm.education "></Select>
                <Input :config="dataForm.identity"/>
                <Input :config="dataForm.phone"/>
                <Input :config="dataForm.address"/>
                <div class="experience">
                    <div class="title">工作经验</div>
                    <textarea v-model="dataForm.experience.value" placeholder-class="exper" :placeholder="dataForm.experience.text"></textarea>
                </div>
                <div class="experience">
                    <div class="title">备注</div>
                    <textarea v-model="dataForm.remark.value" placeholder-class="exper" :placeholder="dataForm.remark.text"></textarea>
                </div>
                <div class="nextBtn" @click="nextBtn">下一步</div>
            </van-form>
        </div>
        <!-- 提示框 -->
      <toast v-if="toastShow" :config="configData"></toast>
    </div>
</template>     
<script type="">
// let form = {
//     username:'',
//     nation:''
// }
// import * as apiCommon from "@/api/common";
import select from "../../components/select";
import input from "../../components/input";
import toast from "@/components/toast";
// import wx from "weixin-js-sdk";
export default {
  data() {
    return {
      value: "",
      popShow: false,
      dataForm: {
        username: {
          value: "",
          title: "姓名",
          text: "请输入姓名"
        },
        nation: {
          value: "",
          title: "民族",
          text: "请输入民族"
        },
        identity: {
          title: "身份证",
          text: "请输入身份证号"
        },
        phone: {
          title: "手机号",
          text: "请输入手机号",
          value: ""
        },
        address: {
          title: "居住地址",
          text: "请输入居住地址",
          value: ""
        },
        experience: {
          title: "工作经验",
          text: "请简要填写工作经验",
          value: ""
        },
        remark: {
          title: "备注",
          text: "选填",
          value: ""
        },
        sex: {
          list: ["女", "男"],
          value: "",
          title: "性别",
          text: "点击选择性别",
          boxWidth: "width:230px"
        },
        politics: {
          list: ["群众", "团员", "党员"],
          value: "",
          title: "政治面貌",
          text: "点击选择政治面貌",
          boxWidth: "width:320px"
        },
        education: {
          list: ["初中", "高中", "专科", "本科", "研究生"],
          value: "",
          title: "学历",
          text: "点击选择学历",
          boxWidth: "width:320px"
        }
      },
      configData: {
        title: ""
      },
      toastShow: false,
      from: "",
      from2: ""
    };
  },
  components: {
    Select: select,
    Input: input,
    toast: toast
  },
  created() {
    document.title = "工会报名信息填写";
    if(JSON.parse(localStorage.getItem("temp2"))){
      this.dataForm = JSON.parse(localStorage.getItem("temp2"))
    }
  },
  methods: {
      mineMessage() {
      console.log(1, "2");
      //       wx.config({
      //    debug: false,
      //    appId: '',
      //    timestamp: ,
      //    nonceStr: '',
      //    signature: '',
      //    jsApiList: [
      //      // 所有要调用的 API 都要加到这个列表中
      //      'getLocation',
      //      'hideOptionMenu',
      //      'closeWindow'
      //    ]
      //  });
      // wx.ready(function() {
      // wx.closeWindow();
      this.$router.push("../lador/service");
      // });
    },
    onSubmit(values) {
      console.log("submit", values);
    },
    nextBtn() {
      console.log(this.dataForm, "data");
      for (var i in this.dataForm) {
        console.log(i);
        if(i!="remark"){
        if (!this.dataForm[i].value.trim()) {
          this.toastShow = true;
          this.configData.title = this.dataForm[i].text;
          setTimeout(() => {
            this.toastShow = false;
          }, 1000);
          return false;
        }
        }
      }
      if (!(/^1[3456789]\d{9}$/.test(this.dataForm.phone.value))) {
        this.toastShow = true;
        this.configData.title = "请输入正确的手机号";
        setTimeout(() => {
          this.toastShow = false;
        }, 1000);
        return false;
      }
      let obj = JSON.stringify(this.dataForm); //转化为JSON字符串
      localStorage.setItem("temp2", obj);
      this.$router.push("/lador/material");
    },

    imgBtnClone(index) {
      if (index == 0) {
        this.fileList = [];
      } else if (index == 1) {
        this.fileList1 = [];
      } else {
        this.fileList2 = [];
      }
    }
  }
};
</script>
<style >
.messageTitle .messageIcon {
  width: 36px;
  height: 36px;
  margin-right: 20px;
}
.messageTitle {
  text-align: right;
  margin-right: 20px;
  font-size: 24px;
  margin-top: 20px;
  position:absolute;
  z-index: 10;
  top: 30px;
  right: 20px;
  color: #fff;
}
.header_btn {
  font-size: 50px;
  font-family: PingFang SC;
  font-weight: 800;
  color: #ffffff;
  opacity: 1;
  text-align: center;
  margin: 40px auto;
}
.content {
  position: absolute;
  top: 30px;
  left: 0;
}
.contaners {
  width: 100%;
  height: 1600px;
}
.contaners img {
  width: 100%;
  /* height: 100%; */
}
.from_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1600px;
}
.from_info {
  font-size: 20px;
  color: #fff;
  margin-top: 20px;
  text-align: center;
}
.from_title {
  font-size: 50px;
  color: #fff;
  text-align: center;
  margin: 34px auto;
}

/* 覆盖样式 */
.van-cell {
  background-color: transparent;
  font-size: 25px;
  width: 626px;
}
.van-field__control {
  /* width: 428px; */
  height: 53px;
  border: 2px solid #ffffff;
  border-radius: 5px;
  padding-left: 15px;
  color: rgba(225, 225, 225, 0.5);
}
.van-field__label {
  width: 150px;
  height: 53px;
  line-height: 53px;
  text-align: right;
  color: #fff;
  font-size: 33px;
  margin-right: 20px;
}
.van-picker__cancel,
.van-picker__confirm {
  font-size: 30px;
}
.van-cell::after {
  border-bottom: none;
}
.van-ellipsis {
  font-size: 30px;
}
.van-form > div {
  margin-bottom: 30px;
}
.experience {
  display: flex;
  font-size: 35px;
  color: #fff;
}
.title {
  width: 170px;
  text-align: right;
  margin-right: 20px;
}
textarea {
  width: 426px;
  height: 178px;
  border: 2px solid #ffffff;
  opacity: 1;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  background-color: transparent;
  font-size: 25px;
  color: #fff;
}
.nextBtn {
  width: 200px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #2a7bb5;
  opacity: 1;
  background-color: #fff;
  border-radius: 45px;
  margin: 60px auto 0;
}

.exper {
  color: #fff;
}

textarea {
  /* color: rgba(225, 225, 225, 0.5); */
}
</style>
