<template>
    <div>
        <van-field 
            :style="config.boxWidth"
            readonly
            clickable
            name="picker"
            :value="config.value"
            :label="config.title"
            :placeholder="config.text"
            @click="showPicker = true"
        />
        <van-popup v-model="showPicker" position="bottom">
            <van-picker
                show-toolbar
                :columns="listArray"
                @confirm="onConfirm"
                @cancel="showPicker = false"
            />
        </van-popup>
    </div>
</template>
<script>
    export default{
        props:{
            listArray:{
                type:Array,
            },
            config:{
                type:Object,
            }
        },
        data(){
            return{
                value: '',
                columns: ['杭州', '宁波', '温州', '嘉兴', '湖州'],
                showPicker: false,    
            }
        },
        methods:{
            onConfirm(value) {
                this.config.value = value;
                console.log(this.config,"config")
                this.showPicker = false;
            },
        }
    }
</script>
